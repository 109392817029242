<template>
  <div id="footer" class="bg-grey">
    <div class="row content-container tint" v-if="$store.getters.skin !== 29">
      <div class="col-md-6 spectrum-logo">
        <img
                ref="footerLogo"
                alt="Spectrum.Life logo"
                :src="getLogo"
                v-if="$store.getters.skin !== 20"
                @error="imageErrorHandler"
        />
      </div>
      <div class="col-md-6 copy-right">
        <div>
        <p>© {{year}} Spectrum.Life. {{componentText.rights}}</p>
        </div>
        <div>
          <a
              href="/content-sources"
              target="_blank"
              class="privacy"
          >
            {{ componentText.sources }}
          </a>
        <a
                v-if="skin === 13"
          href="/datenschutzrichtlinie"
          target="_blank"
          class="privacy"
        >
          Datenschutzrichtlinie
        </a> <a
          href="/privacy-policy"
          target="_blank"
          class="privacy"
          v-else
        >
          {{componentText.privacy}}
        </a>
        <a href="/accessibility-policy" target="_blank" v-if="isAccessibilitySite()" class="privacy">
          Accessibility Policy
        </a>
        <a href="/nutzungsbedingungen" target="_blank" v-if="skin=== 13">
          Nutzungsbedingungen
        </a>
        <a href="/terms-and-conditions" target="_blank" v-else>
          {{componentText.terms}}
        </a>
        </div>
        <div class="col-12 logout mt-2 pr-0" v-if="$store.state.accessCode">
          <a @click="logout()">{{componentText.logout}}</a>
        </div>
        <div class="mt-3">
          <language-select></language-select>
        </div>
        <div class="lloyd-and-whyte" v-if="skin===15">
          <a href="tel:01823 250700">T: 01823 250700</a><br>
          <a href="mailto: WellbeingMatters@Lloydwhyte.com">WellbeingMatters@Lloydwhyte.com</a>
        </div>
      </div>
    </div>
    <div class="bupa-footer bg-brand-secondary" v-else>
      <div class="content-container pl-md-0 pl-5" >
        <div class="row py-5">
          <div class="col-md-3 col-sm-12 d-flex flex-column align-items-start ">
            <img src="/img/gp/bupa_logo.svg" class="img-fluid mb-5 mb-md-0">
          </div>
          <div class="col-md-3 col-sm-12 d-flex flex-column align-items-start">
            <div class="pb-3"><b>Bupa Touch</b></div>
            <a target="_blank" href="https://www.bupa.co.uk/oneidentity/dashboard/accountsettings" class="pb-3 ">Settings</a>
            <a target="_blank" href="https://www.bupa.co.uk/touchdashboard/dashboard/marketingconsenteditpreferences" class="pb-3">Preferences</a>
            <a target="_blank" href="https://www.bupa.co.uk/accessibility" class="pb-3">Accessibility</a>
          </div>
          <div class="col-md-3 col-sm-12 d-flex flex-column align-items-start">
            <div class="pb-3"><b>Legal</b></div>
            <a target="_blank" href="https://www.bupa.co.uk/legal-notices/bupa-touch-terms-and-conditions" class="pb-3">Terms of use</a>
            <a target="_blank" href="https://www.bupa.co.uk/legal-notices/data-tips" class="pb-3">Data tips</a>
            <a target="_blank" href="https://www.bupa.co.uk/legal-notices/privacy-and-cookies" class="pb-3">Privacy and cookies</a>
          </div>
          <div class="col-md-3 col-sm-12 d-flex flex-column align-items-star text-left">
            <div class="pb-3"><b>Get help</b></div>
            <a target="_blank" href="https://www.bupa.co.uk/touchdashboard/dashboard/contact-us" class="pb-3">Help and contact information</a>
            <a target="_blank" href="https://dev.finder.bupa.co.uk/" class="pb-3">Find a Bupa consultant or facility</a>
            <a target="_blank" href="https://www.bupa.co.uk/health-information" class="pb-3">Health information</a>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-left pb-3 ">
            <div class="pr-4 pr-md-0"> We're teaming up with ParalympicsGB. <b><a target="_blank" href="https://www.bupa.co.uk/paralympics" class="underlined">Read about our partnership</a></b>.</div>
          </div>
          <div class="horizontal-divider col-12 row"></div>
        </div>
        <div class="row pt-4">
          <div class="col-12 d-flex flex-row justify-content-between flex-wrap align-items-center">
            <div><a target="_blank" href="https://www.bupa.co.uk/copyright">Copyright Bupa {{year}}</a></div>
            <div class="pr-4 pr-md-0"><a target="_blank" href="https://www.bupa.com/site-services/modern-slavery-statement">Modern slavery statement</a></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment'
import {mapGetters} from "vuex"
import LanguageSelect from "./LanguageSelect";
export default {
  name: "Footer",
  components: {LanguageSelect},
  data() {
    return {
      defaultLightLogo: '/img/spectrum_life_logo_light.png',
      defaultDarkLogo: '/img/spectrum_life_logo_dark.png',
      errorWithLogo: false
    };
  },
  mounted() {
  },
  methods: {
    imageErrorHandler(e) {
      this.errorWithLogo = true;
      e.target.src=this.darkMode ? this.defaultDarkLogo : this.defaultLightLogo;
    },
    logout(){
      this.$store.dispatch('logout')
      this.$router.push("/login");
    }
  },
  watch: {
    darkMode(val) {
      if(this.errorWithLogo) {
        this.$refs.footerLogo.src = val ? this.defaultDarkLogo : this.defaultLightLogo;
      }
    },
  },
  computed:{
        ...mapGetters(['hardcoded', 'skin','darkMode']),
      componentText() {
        return this.getText.loggedIn.footer;
      },
      privacy(){
          if(this.skin === 8){
              return "/img/boi/BOI-Wellbeing-Privacy-Policy.pdf"
          }else if(this.skin=== 13){
            return "/datenschutzrichtlinie"
          }
          else{
              return "/privacy-policy"
          }
      },
        terms(){
            if(this.skin === 8){
                return "/img/boi/BOI-Wellbeing-TCs.pdf"
            }else if(this.skin === 13){
              return "/nutzungsbedingungen"
            }
            else{
                return "/terms-and-conditions"
            }
        },
      year(){
          return moment().format('YYYY')
      },
      getLogo() {
        if (this.hardcoded && this.hardcoded.client_logo) {
          return this.hardcoded.client_logo;
        } else {
          const logoUrl = this.$store.state.client.client_logo;
          if(logoUrl === "https://healthcoach-media.com/res/images/esp/customise/spectrum-life-logo.png") {
            return this.darkMode ? '/img/spectrum_life_logo_dark.png' : '/img/spectrum_life_logo_light.png';
          } else {
            return logoUrl;
          }
        }
      },
    }
};
</script>
<style lang="less">
#footer {
  padding: 30px 0;
  text-align: center;
  .spectrum-logo {
    max-width: 250px;
    text-align: center;
    margin-right: auto;
    align-items: center;
    display: flex;
    img {
      margin: auto;
      width: 100%;
    }
  }
  .copy-right, .dropdown-toggle {
    color: #525252;
    font-family: "DMSans", sans-serif;
    font-size: 1.2rem;
    text-align: right;
    a {
      text-decoration: none;
      color: #525252;
    }
    .privacy {
      border-style: solid;
      border-width: 0 1px 0 0;
      border-color: #727577;
      padding: 0 3px 5px 0;
      margin-right: 5px;
    }
  }
  .dropdown-toggle{
    letter-spacing: 0;
    background: transparent;
    &:active{
      background: transparent;
    }
  }
  .dropdown-menu{
    font-size: 1.4rem;
  }
  @media only screen and (max-width: 768px){
    .copy-right {
      padding: 20px 0;
    }
  }
  .bupa-footer{
    font-size: 1.4rem;
    color: white;
    .content-container{
      overflow: hidden;
    }
    .horizontal-divider{
      height: 1px;
      background-color: white;
    }
    a{
      color: white;
      &:hover{
        text-decoration: underline;
      }
    }
    img{
      max-width: 200px;
    }
    b{
      font-family: 'FilsonProBold', serif;
    }
    .underlined{
      text-decoration: underline;
    }
  }
}
</style>
